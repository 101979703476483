<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thông tin tài khoản kế toán'">
          <template v-slot:preview>
            <b-form @submit="onSubmit" ref="form" lazy-validation class="row">
              <!-- Input session -->
              <b-container class="bv-example-row">
                <b-row>
                  <b-col lg="6" md="6" sm="12" xs="12">
                    <!-- LOẠI TÀI KHOẢN -->
                    <b-form-group
                      id="input-group-type"
                      label-cols-md="3"
                      label="Loại tài khoản "
                      label-for="select-account-type"
                      label-align-md="right"
                    >
                      <!-- <label for="input-name col-md-2">Loại tài khoản:</label> -->
                      <b-form-select
                        id="select-account-type"
                        required
                        v-model="mainModel.type"
                        aria-describedby="input-store-live-feedback"
                        :options="typeOptions"
                        size="sm"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >- Loại tài khoản -</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                    <!-- CỬA HÀNG -->
                    <b-form-group
                      id="input-group-store"
                      label-cols-md="3"
                      label="Cửa hàng "
                      label-for="select-store"
                      label-align-md="right"
                    >
                      <vue-autosuggest
                        class="border-radius-none"
                        aria-describedby="input-store-live-feedback"
                        :suggestions="filteredOptionsStore"
                        @selected="onSelectedStore"
                        :limit="10"
                        @input="onInputChangeStore"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Chọn cửa hàng',
                          style: 'border-radius:0px!important',
                        }"
                        v-model="searchStore"
                      >
                        <div
                          slot-scope="{ suggestion }"
                          style="display: flex; align-items: center"
                        >
                          <div style="{ display: 'flex', color: 'navyblue'}">
                            {{ suggestion.item.suggestionName }}
                          </div>
                        </div></vue-autosuggest
                      >
                    </b-form-group>
                    <!-- TÀI KHOẢN CHA -->
                    <b-form-group
                      id="input-group-parent"
                      label-cols-md="3"
                      label="Tài khoản cha"
                      label-for="autosuggest__input"
                      label-align-md="right"
                    >
                      <!-- <label for="input-code">Tài khoản cha:</label> -->
                      <vue-autosuggest
                        v-model="displayModel.selectedParent"
                        :suggestions="displayModel.filteredOptions"
                        @selected="onSelected"
                        :limit="10"
                        @input="onInputChange"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Chọn tài khoản cha',
                        }"
                      ></vue-autosuggest>
                    </b-form-group>
                    <!-- MÃ TÀI KHOẢN -->
                    <b-form-group
                      id="input-group-code"
                      label-cols-md="3"
                      label="Mã tài khoản"
                      label-for="input-code"
                      label-align-md="right"
                      class="required-control"
                    >
                      <!-- <label for="input-code">Mã tài khoản:</label> -->
                      <b-form-input
                        size="sm"
                        id="input-code"
                        v-model="$v.mainModel.code.$model"
                        placeholder="Nhập mã"
                        required
                        :state="validateItem('code')"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-code-feedback"
                        >Vui lòng nhập thông tin.</b-form-invalid-feedback
                      >
                    </b-form-group>
                    <!-- TÊN TÀI KHOẢN -->
                    <b-form-group
                      id="input-group-name"
                      label-cols-md="3"
                      label="Tên tài khoản"
                      label-for="input-name"
                      label-align-md="right"
                      required
                      class="required-control"
                    >
                      <!-- <label for="input-code">Tên tài khoản:</label> -->
                      <b-form-input
                        size="sm"
                        id="input-name"
                        v-model="$v.mainModel.name.$model"
                        placeholder="Nhập tên"
                        :state="validateItem('name')"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-name-feedback"
                        >Vui lòng nhập thông tin.</b-form-invalid-feedback
                      >
                    </b-form-group>
                    <!-- TRẠNG THÁI -->
                    <b-form-group
                      id="input-group-status"
                      label-cols-md="3"
                      label="Trạng thái"
                      label-for="select-status"
                      label-align-md="right"
                    >
                      <!-- <label for="input-name">Trạng thái:</label> -->
                      <b-form-select
                        id="select-status"
                        v-model="mainModel.status"
                        :options="statusOptions"
                        aria-describedby="input-active-live-feedback"
                        size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-container>
            </b-form>
          </template>
          <template v-slot:foot>
            <!-- <b-col lg="4" class="pb-2"> -->
            <b-container class="bv-example-row">
              <b-row>
                <b-col md="6" xs="12" sm="12">
                  <b-button
                    style="fontweight: 600; width: 70px; margin-right: 10px"
                    variant="primary"
                    size="sm"
                    type="submit"
                    @click="onSubmit"
                    >Lưu</b-button
                  >
                  <router-link to="/accounting/accounts" tag="button">
                    <b-button
                      style="font-weight: 600; width: 70px"
                      variant="secondary"
                      size="sm"
                      >Hủy</b-button
                    >
                  </router-link>
                </b-col>
              </b-row>
            </b-container>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style>
.center-label label {
  margin-bottom: 0px;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { removeAccents } from './../../../utils/common';
import { getToastInstance } from './../../../utils/toastHelper';
import ApiService from '@/core/services/api.service';
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  validations: {
    mainModel: {
      code: {
        required,
        minLength: minLength(1),
      },
      name: {
        required,
        minLength: minLength(1),
      },
    },
  },
  data() {
    return {
      // Model of view.
      mainModel: {
        parentId: '',
        code: '',
        name: '',
        storeId: 0,
        status: 1,
        type: null,
      },
      // For display on view.
      displayModel: {
        selectedParent: '',
        filteredOptions: [],
        listAccSearch: [],
      },
      listAccSearch: [],
      selectedParent: '',
      options: [
        {
          data: [],
        },
      ],
      filteredOptions: [],
      listStore: [],
      selectedStore: 0,
      parentId: '',
      code: '',
      name: '',
      selectedStatus: 1,
      statusOptions: [
        { value: '1', text: 'Kích hoạt' },
        { value: '2', text: 'Không kích hoạt' },
      ],
      selectedType: 1,
      typeOptions: [
        { value: '1', text: 'Tài khoản tiền mặt' },
        { value: '2', text: 'Tài khoản ngân hàng' },
        { value: '3', text: 'Tài khoản quẹt thẻ' },
        { value: '4', text: 'Tài khoản trả góp' },
      ],
      optionsStore: [
        {
          data: [],
        },
      ],
      filteredOptionsStore: [],
      searchStore: '',
    };
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.fetchStore();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Kế toán', route: '/accountants' },
      { title: 'Thêm mới tài khoản kế toán', route: '/accountants' },
    ]);
    let parentAccId = this.$route.query.parentId;
    if (parentAccId !== undefined) {
      this.parentId = parentAccId;
      this.getAccountantById();
    }
  },
  computed: {
    selectedClassParent() {
      const acc = this.listAccSearch.find(
        (p) => p.name === this.displayModel.selectedParent
      );
      if (acc) {
        return acc.id;
      } else {
        return null;
      }
    },
  },
  created() {
    this.searchParent();
  },
  methods: {
    //----*CLIENT HANDLERS
    // Get toast instance.
    ...getToastInstance(),

    // Validate input.
    validateItem(name) {
      const { $dirty, $error } = this.$v.mainModel[name];
      return $dirty ? !$error : null;
    },
    getAccountantById: async function () {
      if (!this.parentId) {
        return;
      } else {
        await ApiService.setHeader();
        await ApiService.get('accountants/' + this.parentId).then((res) => {
          this.selectedStore = res.data.data.storeId;
          this.selectedParent = res.data.data.name;
          this.displayModel.selectedParent = res.data.data.name;
          this.mainModel.type = res.data.data.type;
        });
      }
    },
    onInputChange(text) {
      this.displayModel.selectedParent = text;

      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            removeAccents(item)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.displayModel.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onSelected(option) {
      // this.mainModel.parentId = option.item;
      this.displayModel.selectedParent = option.item;
      this.mainModel.parentId = this.selectedClassParent;
    },
    onSubmit: async function () {
      this.$v.mainModel.$touch();
      if (this.$v.mainModel.$anyError) {
        return;
      }
      this.mainModel.parentId = this.selectedClassParent;
      let params = this.mainModel;
      ApiService.post('accountants', { params })
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.$router.push({
              name: 'list-accountant',
              params: { createAccountantSuccess: true, message: 'Success' },
            });
            this.makeToastSuccess(message);
          } else {
            this.makeToastFaile(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
        });
    },
    fetchStore: async function () {
      ApiService.setHeader();
      ApiService.get(
        `stores?name=${this.search || ''}&limit=1000&page=${this.page || 1}`
      ).then((response) => {
        response.data.data.list_store.map((element) => {
          let store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.optionsStore[0].data.push(store);
        });
        let tmp = [{ data: this.optionsStore[0].data }];
        this.filteredOptionsStore = tmp;
      });
    },
    searchParent: async function () {
      this.listAccSearch = [];
      ApiService.setHeader();
      ApiService.query('accountants/getAll').then((response) => {
        this.options[0].data = [];
        response.data.data.forEach((item) => {
          let acc = {
            id: item.id,
            name: item.name,
            code: item.code,
          };
          this.listAccSearch.push(acc);
          this.options[0].data.push(acc.name);
        });
      });
    },
    onSelectedStore(option) {
      this.searchStore = option.item.name;
      this.mainModel.storeId = option.item.id;
    },
    onInputChangeStore(text) {
      this.searchStore = text;
      const filteredData = this.optionsStore[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsStore = [
        {
          data: filteredData,
        },
      ];
    },
  },
};
</script>

<style>
/* .form-group label {
  font-weight: 600;
} */

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5eaee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
